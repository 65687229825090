import ZerionLogo from 'images/zerion-header-logo.png'

type TApplicationParams = {
  highlightColor: string
  primaryTextColor: string
  logo: string
  backgroundColor: string
}

const applicationParams: TApplicationParams = {
  highlightColor: '#2461ED',
  primaryTextColor: '#121212',
  logo: ZerionLogo,
  backgroundColor: '#F7F7FB'
}

export default applicationParams