import applicationOptions from 'configs/application'

const themes = {
  dark: {
    primaryTextColor: '#FFF', // main color of text
    secondaryTextColor: '#000', // secondary color of text
    additionalTextColor: '#C3C3C3', // secondary color of text
    extraTextColor: '#6A6A6A', // secondary color of text
    disabledTextColor: '#E0E0E0', // disabled color of text


    dangerTextColor: '#FF2C2C', // danger color of text
    noteTextColor: '#D4CCFF', // note color of text
    primaryBorderColor: '#E4E4E4',
    secondaryBorderColor: '#FFF',
    additionalBorderColor: '#272727',
    primaryColor: '#F7F8FC', // main color of bg
    secondaryColor: '#363740',
    blankColor: '#131214',
    menuItemActive: '#3E4049',
    primaryHighlightColor: '#2B32EA',
    loaderPrimaryColor: '#000',
    widgetColor: '#FFF',
    loaderColor: '#BBB0FF',
    linkColor: '#BBB0FF',
  
    buttonDefaultColor: '#FFF',
    buttonDisabledColor: '#272727',
    buttonHoverColor: '#4047EC',
    buttonActiveColor: '#2229DF',
    buttonDisabledTextColor: '#C5C7CD',
    buttonBorderColor: '#565656',

    tagDefaultColor: '#DFE0EB',
    tagErrorColor: '#FF2C2C',
    tagInfoColor: '#2B32EA',
    tagSuccessColor: '#21F142',

    noteDefaultBgColor: '#343248',
    noteDefaultTextColor: '#D4CCFF',

    noteAttentionBgColor: '#272727',
    noteAttentionTextColor: '#C3C3C3',
    noteWarningTextColor: '#3E2909',
    toastBackgroundColor: '#2D2A3D',

    inputBgColor: '#272727',
    inputBorderColor: '#565656',
    inputBorderHoverColor: '#C3C3C3',
    inputPlaceholderColor: '#565656',

    ethereumLogoColor: '#0EBDCD',
    polygonLogoColor: '#0EBDCD',
    buttonGradient: 'linear-gradient(to right, #00B2FF 0%, #FA00FF 50%, #00B2FF 100%)',

  },

  light: {
    primaryTextColor: applicationOptions.primaryTextColor, // main ccolor of text
    secondaryTextColor: '#FFF', // secondary color of text
    additionalTextColor: '#9D9D9D', // secondary color of text
    extraTextColor: '#6A6A6A', // secondary color of text
    disabledTextColor: '#DFE0EB', // disabled color of text
    dangerTextColor: '#FF2C2C', // danger color of text
    noteTextColor: '#D4CCFF', // note color of text
    primaryBorderColor: '#000',
    secondaryBorderColor: '#000',
    additionalBorderColor: '#272727',
    primaryColor: applicationOptions.backgroundColor, // main color of bg
    secondaryColor: '#363740', // secondary coplor of bg
    blankColor: applicationOptions.backgroundColor, // secondary coplor of bg
    menuItemActive: '#3E4049',
    primaryHighlightColor: '#2B32EA',
    loaderPrimaryColor: '#000',
    widgetColor: '#FFF',
    loaderColor: applicationOptions.highlightColor,
    linkColor: applicationOptions.highlightColor,
    buttonDefaultColor: applicationOptions.highlightColor,
    buttonDisabledColor: '#E4E4E4',
    buttonHoverColor: '#4047EC',
    buttonActiveColor: '#2229DF',
    buttonDisabledTextColor: '#9D9D9D',
    buttonBorderColor: '#E4E4E4',
    tagDefaultColor: '#DFE0EB',
    tagErrorColor: '#FF2C2C',
    tagInfoColor: '#2B32EA',
    tagSuccessColor: '#21F142',
    noteDefaultBgColor: '#E4EDFF',
    noteDefaultTextColor: applicationOptions.highlightColor,
    noteAttentionBgColor: '#272727',
    noteAttentionTextColor: '#C3C3C3',
    noteWarningTextColor: '#3E2909',

    inputBgColor: '#FFF',
    inputBorderColor: '#E4E4E4',
    inputBorderHoverColor: '#C3C3C3',
    inputBorderFocusColor: applicationOptions.highlightColor,
    inputPlaceholderColor: '#9D9D9D',

    ethereumLogoColor: '#0EBDCD',
    polygonLogoColor: '#0EBDCD',
    toastBackgroundColor: '#2D2A3D',
    buttonGradient: 'linear-gradient(to right, #00B2FF 0%, #FA00FF 50%, #00B2FF 100%)',
  }
}

export default themes